import axios from "axios";

/**
 * 获取报工查询列表——分页列表
 */
export const fetchWorkReportList = ({ pageNum, pageSize, ...param }) => {
  return axios({
    url: "/dtsum/zongtong/report/statisticalFormsController/jobEnquiry",
    method: "GET",
    params: {
      pageNum,
      pageSize,
      param
    }
  });
};
