import axios from "axios";

/**
 * 获取所有挡车工的数据列表
 */
 export const fetchAllWorkerList = () => {
  return axios({
    url: "/dtsum/zongtong/order/ProductExecuteController/findWorkerNameList",
    method: "GET"
  });
};
