<template>
  <div class="page-work-report-query" id="page-work-report-query">
    <!-- 顶部筛选 -->
    <div class="common-filter-block">
      <div class="common-filter-item">
        <span style="margin-right: 10px">选择时间</span>
        <DatePicker
          style="width: 220px"
          type="daterange"
          placeholder="请选择日期范围"
          v-model="filterDateRange"
          @on-change="handleDateRangeChange"
        />
      </div>

      <div class="common-filter-item">
        <span style="margin-right: 10px">坯布指示单号</span>
        <Input
          placeholder="请输入搜索内容"
          v-model.trim="listParam.weaveDetail"
          @keyup.enter.native="searchTableList"
          style="width: 140px"
        />
      </div>
      <div class="common-filter-item">
        <span style="margin-right: 10px">班次</span>
        <Select style="width: 120px" v-model="listParam.workTime" @on-change="searchTableList">
          <Option v-for="item in productShiftOptions" :value="item.value" :key="item.value">
            {{ item.label }}
          </Option>
        </Select>
      </div>
      <div class="common-filter-item">
        <span style="margin-right: 10px">挡车工</span>
        <Select
          clearable
          v-model="listParam.workerName"
          style="width: 120px"
          @on-change="searchTableList"
        >
          <Option value="all" key="all">全部</Option>
          <Option v-for="(item, index) in allWorkerNameList" :value="item.workerName" :key="index">
            {{ item.workerName }}
          </Option>
        </Select>
      </div>
      <div class="common-filter-item">
        <span style="margin-right: 10px">机台号</span>
        <Input
          placeholder="请输入搜索内容"
          v-model.trim="listParam.machineNo"
          @keyup.enter.native="searchTableList"
          style="width: 180px"
        />
      </div>
      <div class="common-filter-item">
        <span style="margin-right: 10px">重量 ＞</span>
        <Input
          placeholder="请输入搜索内容"
          v-model.trim="listParam.weight"
          @keyup.enter.native="searchTableList"
          style="width: 180px"
        />
      </div>
    </div>

    <div class="common-action-block">
      <div class="common-action-item" @click="searchTableList">
        <i class="iconfont iconicon-shuaxin"></i>
        刷新
      </div>
      <!-- <div class="common-action-item" @click="exportTableList">
        <i class="iconfont iconicon-daochu"></i>
        导出
      </div> -->
    </div>

    <!-- 表格 -->
    <Table border :loading="loading" :columns="tableColumns" :data="tableData"></Table>

    <!-- 分页 -->
    <Page
      class-name="common-page"
      show-sizer
      show-elevator
      show-total
      :total="listDataTotal"
      :page-size="listParam.pageSize"
      :current="listParam.pageNum"
      @on-page-size-change="pageSizeChange"
      @on-change="pageNumberChange"
    />
  </div>
</template>

<script>
import { fetchAllWorkerList } from "@/api";
import { fetchWorkReportList } from "./api";
import { productShiftOptions } from "./constant";

export default {
  name: "WorkReportQuery",
  data() {
    return {
      productShiftOptions, // 排班班次的 Select 下拉框列表选项

      allWorkerNameList: [], // 形如 [{name: 'aaa'},{name:'bbb'}]，

      filterDateRange: [
        this.moment(Date.now() - 86400000 * 7).format("YYYY-MM-DD"),
        this.moment(new Date()).format("YYYY-MM-DD"),
      ], // 待格式化的数据(前端用)，对应————报工筛选开始时间和报工筛选结束时间

      listParam: {
        startDate: this.moment(Date.now() - 86400000 * 7).format("YYYY-MM-DD"), // 报工筛选开始时间，已格式化，形如 "2021-07-16"
        endDate: this.moment(Date.now()).format("YYYY-MM-DD"), // 报工筛选结束时间，数据量比较大，前端限制，默认查近七天的

        workerName: "all", // 挡车工姓名, "all"表示查询全部
        workTime: -1, // 班次 1为白班,2为夜班,3为白转黑，4为黑转白，-1全部
        weaveDetail: "", // 坯布指示单号
        machineNo: "", // 机台号
        weight: "", // 重量，筛选的是大于该重量的数据，默认传-1，表示忽略次筛选条件

        pageNum: 1,
        pageSize: 10,
      },

      loading: false,
      tableData: [],
      listDataTotal: 0, // 分页器数据总数
      tableColumns: [
        {
          title: "序号",
          type: "index",
          minWidth: 80,
        },
        {
          title: "坯布指示单号",
          key: "weaveDetail",
          minWidth: 160,
        },
        {
          title: "报工日期",
          key: "workDate",
          minWidth: 120,
        },
        {
          title: "班次",
          key: "workTime",
          minWidth: 100,
        },

        {
          title: "挡车工",
          key: "workerName",
          minWidth: 120,
        },
        {
          title: "机台号",
          key: "machineNo",
          minWidth: 120,
        },
        {
          title: "匹数",
          key: "pieceNum",
          minWidth: 120,
        },
        {
          title: "产量",
          key: "weight",
          minWidth: 140,
        },
      ],
    };
  },
  created() {
    this.getAllWorkerList();
    this.getTableListData();
  },
  methods: {
    handleDateRangeChange(formatDate, DateObj) {
      this.listParam.startDate = formatDate[0];
      this.listParam.endDate = formatDate[1];

      this.searchTableList();
    },

    getAllWorkerList() {
      fetchAllWorkerList()
        .then((res) => {
          if (res.data.success === 1) {
            this.allWorkerNameList = res.data.body.workerNameList;
          } else {
            this.$Message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getTableListData() {
      const requestParam = { ...this.listParam };

      if (this.listParam.weight !== "") {
        this.listParam.weight = Number(this.listParam.weight);
      }

      if (typeof this.listParam.weight === "number" && !isNaN(this.listParam.weight)) {
        requestParam.weight = this.listParam.weight;
      } else {
        requestParam.weight = -1; // 表示搜索全部
      }

      fetchWorkReportList(requestParam)
        .then((res) => {
          if (res.data.success === 1) {
            const { total, list } = res.data.body.list;

            this.tableData = list;
            this.listDataTotal = total;
          } else {
            this.tableData = [];
            this.listDataTotal = 0;
          }

          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    searchTableList() {
      this.listParam.pageNum = 1;
      this.getTableListData();
    },

    pageSizeChange(pageSize) {
      this.listParam.pageSize = pageSize;
      this.listParam.pageNum = 1;
      this.getTableListData();
    },
    pageNumberChange(pageNum) {
      this.listParam.pageNum = pageNum;
      this.getTableListData();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/common.scss";
</style>
<style lang="scss"  >
#page-work-report-query {
}
</style>
